import React from 'react';
import "./WhatApp.css";

const WhatApp = () => {
  return (
    <div>
      <a href="https://api.whatsapp.com/send?phone=917905308732" class="float" target="_blank">
<i class="fa fa-whatsapp my-float"></i>
</a>
    </div>
  )
}

export default WhatApp
