import React, { useState } from "react";
import "./Slider.css";
import EastOutlinedIcon from "@mui/icons-material/EastOutlined";
import WestOutlinedIcon from "@mui/icons-material/WestOutlined";
import img1 from "../images/carousel-1.jpg";
import img2 from "../images/carousel-2.jpg";
import img29 from "../images/img-29.jpg"
import img30 from "../images/img-30.jpg"
import img12 from "../images/img-12.jpg"

const Slider = () => {

    const [currentSlide, setCurrentSlide] = useState(0)

  const data = [
    "https://images.pexels.com/photos/1549200/pexels-photo-1549200.jpeg?auto=compress&cs=tinysrgb&w=1600",
    "https://images.pexels.com/photos/949670/pexels-photo-949670.jpeg?auto=compress&cs=tinysrgb&w=1600",
    "https://images.pexels.com/photos/837140/pexels-photo-837140.jpeg?auto=compress&cs=tinysrgb&w=1600",
  ];

  const prevSlide = () => {
    setCurrentSlide(currentSlide === 0 ? 2 : (prev) => prev - 1) 
  }
  const nextSlide = () => {
    setCurrentSlide(currentSlide === 2 ? 0 : (prev) => prev + 1) 
  }

  return (
    <div className="sliders">
      <div className="containerss" style={{transform:`translateX(-${currentSlide * 100}vw)`}}>
        <img src={img29} alt="" />
        <img src={img30} alt="" />
        <img src={img12} alt="" />
      </div>
      <div className="iconsss">
        <div className="iconss" onClick={prevSlide}>
        <WestOutlinedIcon />
        </div>
        <div className="iconss" onClick={nextSlide}>
        <EastOutlinedIcon />
        </div>
      </div>
    </div>
  );
};

export default Slider;
