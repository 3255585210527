import './App.css';
import AboutPage from './pages/AboutPage';
import ClassesPage from './pages/ClassesPage';
import ContactUsPage from './pages/ContactUsPage';
import GalleryPage from './pages/GalleryPage';
import MainPage from './pages/MainPage';
import {BrowserRouter, Routes, Route} from 'react-router-dom';
import NoticePage from './pages/NoticePage';
import PageNotFount from './pages/PageNotFount';

function App() {
  return (
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<><MainPage/></>} />
          <Route path='/about' element={<><AboutPage/></>} />
          <Route path='/class' element={<><ClassesPage/></>} />
          <Route path='/gallery' element={<><GalleryPage/></>} />
          <Route path='/contact' element={<><ContactUsPage/></>} />
          <Route path='/notice' element={<><NoticePage/></>} />
          <Route path='*' element={<><PageNotFount/></>} />
        </Routes>
      </BrowserRouter>
  );
}

export default App;
