import React from 'react'

const Up = () => {
  return (
    <div>
      {/* <a href="#" className="btn btn-lg btn-primary btn-lg-square back-to-top">
    <i className="bi bi-arrow-up" />
  </a> */}
    </div>
  )
}

export default Up
