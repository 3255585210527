import React from 'react';
import principal1 from "../images/principal-1.jpg";
import principal2 from "../images/principal-2.jpg";

const Team = () => {
  return (
   <>
     <div>
         <div className="container-xxl py-5">
    <div className="container">
      <div
        className="text-center mx-auto mb-5 wow fadeInUp"
        data-wow-delay="0.1s"
        style={{ maxWidth: 600 }}
      >
        <h1 className="mb-3">Popular Teachers</h1>
        <p>
        Admired and respected by students for their engaging teaching style, approachability, and positive impact on learning.
        </p>
      </div>
      <div className="row g-4">
        <div className="col-md-6 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
          <div className="team-item position-relative">
            <img
              className="img-fluid w-75"
              src={principal2}
              alt="principal-img-2"
              style={{height:"24rem",width:"30rem",borderRadius:"2rem"}}
            />
            <div className="team-text" style={{height:"7rem"}}>
              <h4>Mrs.Reeta Mishra</h4>
              <p> Principal. MA. Bed. N T T</p>
            </div>
          </div>
        </div>
        <div className="col-md-6 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
          <div className="team-item position-relative">
            <img
              className="img-fluid"
              src={principal1}
              alt="principal-img-1"
              style={{height:"24rem",width:"30rem",borderRadius:"2rem"}}
            />
            <div className="team-text" style={{height:"7rem"}}>
              <h3>Mr.R. S Pathak</h3>
              <p>MA(English) BEdManager</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
    </div>
    {/* <Teacher/> */}
   </>
    
  )
}

export default Team
