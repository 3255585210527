import React from 'react'
import { Link } from 'react-router-dom'

const Footer = () => {
  return (
    <div>
  <div
    className="container-fluid bg-dark text-white-50 footer pt-5 mt-5 wow fadeIn"
    data-wow-delay="0.1s"
  >
    <div className="container py-5">
      <div className="row g-5">
        <div className="col-lg-3 col-md-6">
          <h3 className="text-white mb-4">Get In Touch</h3>
          <p className="mb-2">
            <i className="fa fa-map-marker me-3" />
            S.23/42 A-7 Dhelwariya,Choukaghat,Varanasi
          </p>
          <p className="mb-2">
            <i className="fa fa-phone me-3" />
            +91 9839886336
          </p>
          <p className="mb-2">
            <i className="fa fa-envelope me-3" />
            scholarvallypublicschool@gmail.com
          </p>
          <div className="d-flex pt-2">
            <a className="btn btn-outline-light btn-social" href="https://m.facebook.com/story.php?story_fbid=3293758887516823&id=100006482336666&sfnsn=wiwspwa&mibextid=2Rb1fB">
              <i className="fab fa-facebook-f" />
            </a>
            <a className="btn btn-outline-light btn-social" href="https://www.facebook.com/groups/766615883514948/permalink/1636629826513545/?sfnsn=wiwspwa&ref=share&mibextid=2Rb1fB&_rdr">
              <i className="fab fa-facebook-f" />
            </a>
          </div>
        </div>
        <div className="col-lg-3 col-md-6">
          <h3 className="text-white mb-4">Quick Links</h3>
          <Link className="btn btn-link text-white-50" to="/">
            Home
          </Link>
          <Link className="btn btn-link text-white-50" to="/about">
            About-Us
          </Link>
          <Link className="btn btn-link text-white-50" to="/class">
            Classes
          </Link>
          <a className="btn btn-link text-white-50" href="/gallery">
            Gallery
          </a>
          <a className="btn btn-link text-white-50" href="/contact">
            Contact
          </a>
        </div>
        <div className="col-lg-3 col-md-6">
          <h3 className="text-white mb-4">Photo Gallery</h3>
          <div className="row g-2 pt-2">
            <div className="col-4">
              <img
                className="img-fluid rounded bg-light p-1"
                src="/static/img/classes-1.jpg"
                alt=""
              />
            </div>
            <div className="col-4">
              <img
                className="img-fluid rounded bg-light p-1"
                src="/static/img/classes-2.jpg"
                alt=""
              />
            </div>
            <div className="col-4">
              <img
                className="img-fluid rounded bg-light p-1"
                src="/static/img/classes-3.jpg"
                alt=""
              />
            </div>
            <div className="col-4">
              <img
                className="img-fluid rounded bg-light p-1"
                src="/static/img/classes-4.jpg"
                alt=""
              />
            </div>
            <div className="col-4">
              <img
                className="img-fluid rounded bg-light p-1"
                src="/static/img/classes-5.jpg"
                alt=""
              />
            </div>
            <div className="col-4">
              <img
                className="img-fluid rounded bg-light p-1"
                src="/static/img/classes-6.jpg"
                alt=""
              />
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-md-6">
          <h3 className="text-white mb-4">Newsletter</h3>
          <p>Dolor amet sit justo amet elitr clita ipsum elitr est.</p>
          <div className="position-relative mx-auto" style={{ maxWidth: 400 }}>
            <input
              className="form-control bg-transparent w-100 py-3 ps-4 pe-5"
              type="text"
              placeholder="Your email"
            />
            <button
              type="button"
              className="btn btn-primary py-2 position-absolute top-0 end-0 mt-2 me-2"
            >
              SignUp
            </button>
          </div>
        </div>
      </div>
    </div>
    <div className="container">
      <div className="copyright">
        <div className="row">
          <div className="col-md-6 text-center text-md-start mb-3 mb-md-0">
            ©{" "}
            <a className="border-bottom" href="/">
              xyz.com
            </a>
            , All Right Reserved.
            Designed By{" "}
            <a className="border-bottom" href="/">
            </a>
            <br />
            Distributed By:{" "}
            <a
              className="border-bottom"
              href="/"
              target="_blank"
            >
            </a>
          </div>
          <div className="col-md-6 text-center text-md-end">
            <div className="footer-menu">
              <a href="/">Home</a>
              <a href="/">Cookies</a>
              <a href="/">Help</a>
              <a href="/">FQAs</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
    </div>
  )
}

export default Footer
