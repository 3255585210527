import React from 'react'
import { Link } from 'react-router-dom'
import Footer from '../components/Footer'
import logo2 from "../images/Final-Logo.png";
import Facilities from '../components/Facilities';
import img37 from "../images/img-38.jpg";
import WhatApp from '../components/WhatApp';


const ClassesPage = () => {
  return (
    <>
  {/* Navbar Start */}
  <nav className="navbar navbar-expand-lg bg-white navbar-light sticky-top px-4 px-lg-5 py-lg-0">
    <Link to="/" className="navbar-brand">
          <img src={logo2} style={{width: "6rem", height: "6rem", marginLeft:"2.4rem"}} alt="" />
    </Link>
    <button
      type="button"
      className="navbar-toggler"
      data-bs-toggle="collapse"
      data-bs-target="#navbarCollapse"
    >
      <span className="navbar-toggler-icon" />
    </button>
    <div className="collapse navbar-collapse" id="navbarCollapse">
      <div className="navbar-nav mx-auto">
        <Link to="/" className="nav-item nav-link">
          Home
        </Link>
        <Link to="/about" className="nav-item nav-link">
          About Us
        </Link>
        <Link to="/class" className="nav-item nav-link active">
        Features
        </Link>
        <Link to="/gallery" className="nav-item nav-link">
        Gallery
        </Link>
        <Link to="/contact" className="nav-item nav-link">
          Contact Us
        </Link>
      </div>
      <a
        href="/"
        className="btn btn-primary rounded-pill px-3 d-none d-lg-block"
      >
        Join Us
        <i className="fa fa-arrow-right ms-3" />
      </a>
    </div>
  </nav>
  {/* Navbar End */}
  {/* Page Header End */}
  <div className="container-xxl py-5 page-header position-relative mb-5">
    <div className="container py-5">
      <h1 className="display-2 text-white animated slideInDown mb-4">
        Features
      </h1>
      <nav aria-label="breadcrumb animated slideInDown">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <a href="#">Home</a>
          </li>
          <li className="breadcrumb-item">
            <a href="#">Pages</a>
          </li>
          <li className="breadcrumb-item text-white active" aria-current="page">
            Classes
          </li>
        </ol>
      </nav>
    </div>
  </div>
  {/* Page Header End */}
  <Facilities/>
  <div>
        <div className="container-xxl py-5">
    <div className="container">
      <div className="bg-light rounded">
        <div className="row g-0">
          <div className="col-lg-6 wow fadeIn" data-wow-delay="0.5s">
            <div className="h-100 d-flex flex-column justify-content-center p-5">
              <h1 className="mb-4">More School Facilities </h1>
              <p className="mb-4">
              School facilities are essential for creating conducive learning environments
              </p>
              <p>Experience Teacher</p>
              <p>Good Environment</p>
              <p>Indoor & Outdoor sports Activity Centers</p>
              <p>Smart Class Teaching</p>
              <p>Safe & Secure Infrastructure</p>
              <p>24x7 CCTV Surveillance</p>
              <a className="btn btn-primary py-3 px-5" href="/">
                Get Started Now
                <i className="fa fa-arrow-right ms-2" />
              </a>
            </div>
          </div>
          <div
            className="col-lg-6 wow fadeIn"
            data-wow-delay="0.1s"
            style={{ minHeight: 400 }}
          >
            <div className="position-relative h-100">
              <img
                className="position-absolute w-100 h-100 rounded"
                src={img37}
                style={{ objectFit: "cover" }}
                alt='action'
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
    </div>

    
  {/* Appointment Start */}
  {/* <div className="container-xxl py-5">
    <div className="container">
      <div className="bg-light rounded">
        <div className="row g-0">
          <div className="col-lg-6 wow fadeIn" data-wow-delay="0.1s">
            <div className="h-100 d-flex flex-column justify-content-center p-5">
              <h1 className="mb-4">Make Appointment</h1>
              <form>
                <div className="row g-3">
                  <div className="col-sm-6">
                    <div className="form-floating">
                      <input
                        type="text"
                        className="form-control border-0"
                        id="gname"
                        placeholder="Gurdian Name"
                      />
                      <label htmlFor="gname">Gurdian Name</label>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-floating">
                      <input
                        type="email"
                        className="form-control border-0"
                        id="gmail"
                        placeholder="Gurdian Email"
                      />
                      <label htmlFor="gmail">Gurdian Email</label>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-floating">
                      <input
                        type="text"
                        className="form-control border-0"
                        id="cname"
                        placeholder="Child Name"
                      />
                      <label htmlFor="cname">Child Name</label>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-floating">
                      <input
                        type="text"
                        className="form-control border-0"
                        id="cage"
                        placeholder="Child Age"
                      />
                      <label htmlFor="cage">Child Age</label>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="form-floating">
                      <textarea
                        className="form-control border-0"
                        placeholder="Leave a message here"
                        id="message"
                        style={{ height: 100 }}
                        defaultValue={""}
                      />
                      <label htmlFor="message">Message</label>
                    </div>
                  </div>
                  <div className="col-12">
                    <button
                      className="btn btn-primary w-100 py-3"
                      type="submit"
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div
            className="col-lg-6 wow fadeIn"
            data-wow-delay="0.5s"
            style={{ minHeight: 400 }}
          >
            <div className="position-relative h-100">
              <img
                className="position-absolute w-100 h-100 rounded"
                src="/static/img/appointment.jpg"
                style={{ objectFit: "cover" }}
                alt='pp'
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div> */}
  {/* Appointment End */}
  <Footer/>
  <WhatApp/>
</>

  )
}

export default ClassesPage
