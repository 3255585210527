import React from "react";
import Footer from "../components/Footer";
import Facilities from "../components/Facilities";
import About from "../components/About";
import Action from "../components/Action";
import Appointment from "../components/Appointment";
import Team from "../components/Team";
import Testimonial from "../components/Testimonial";
import Up from "../components/Up";
import { Link } from "react-router-dom";
import logo2 from "../images/Final-Logo.png";
import Gallery from "../components/Gallery";
import WhatApp from "../components/WhatApp";
import Slider from "../components/Slider";
import "./MainPage.css";

const MainPage = () => {
  return (
    <div className="container-xxl bg-white p-0">
      <nav className="navbar navbar-expand-lg bg-white navbar-light sticky-top px-4 px-lg-5 py-lg-0">
        <Link to="/" className="navbar-brand">
          <img
            src={logo2}
            style={{ width: "6rem", height: "6rem", marginLeft: "2.4rem" }}
            alt=""
          />
        </Link>
        <button
          type="button"
          className="navbar-toggler"
          data-bs-toggle="collapse"
          data-bs-target="#navbarCollapse"
        >
          <span className="navbar-toggler-icon" />
        </button>
        <div className="collapse navbar-collapse" id="navbarCollapse">
          <div className="navbar-nav mx-auto">
            <Link to="/" className="nav-item nav-link active">
              Home
            </Link>
            <Link to="/about" className="nav-item nav-link">
              About Us
            </Link>
            <Link to="/class" className="nav-item nav-link">
              Features
            </Link>
            <Link to="/gallery" className="nav-item nav-link">
              Gallery
            </Link>
            <Link to="/contact" className="nav-item nav-link">
              Contact Us
            </Link>
          </div>
          <a
            href="https://m.facebook.com/story.php?story_fbid=3293758887516823&id=100006482336666&sfnsn=wiwspwa&mibextid=2Rb1fB"
            className="btn btn-primary rounded-pill px-3 d-none d-lg-block"
          >
           
            <i className="fab fa-facebook-f" />
          </a>
        </div>
      </nav>
      <div className="container-xxl py-5 page-headers position-relative mb-5"></div>
      <Slider />
      <Facilities />
      <About />
      <Action />
      <Gallery />
      <Testimonial />
      <Appointment />
      <Team />
      <Footer />
      <WhatApp />
      <Up />
    </div>
  );
};

export default MainPage;
