import React from "react";
import NoticeBoard from "../pages/NoticePage";

const Testimonial = ({ todo, onDelete }) => {
  return (
    <div>
      <div className="container-xxl py-5">
        <div className="container">
          <div
            className="text-center mx-auto mb-5 wow fadeInUp"
            data-wow-delay="0.1s"
            style={{ maxWidth: 600 }}
          >
            <h1 className="mb-3">Notice Borad</h1>
            <p>
              Upcoming events, such as school dances, field trips, and sporting
              events.Reminders about important deadlines, such as assignments or
              tests.Information about school clubs and activities.
            </p>
          </div>
        </div>
      </div>

      {/* <div>
        <h4>{todo}</h4>
        <p>{todo}</p>
        <button
          className="btn btn-sm btn-danger"
          onClick={() => {
            onDelete(todo);
          }}
        >
          Delete
        </button>
      </div> */}
      <hr />
      <NoticeBoard/>

    </div>
  );
};

export default Testimonial;
