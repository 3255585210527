import React from 'react';
import "./Gallery.css";
import img34 from "../images/img-34.jpg"
import img39 from "../images/img-39.jpg"
import img40 from "../images/img-40.jpg"
import img41 from "../images/img-41.jpg"
import img12 from "../images/img-12.jpg";
import img14 from "../images/img-14.jpg"
import img15 from "../images/img-15.jpg"
import img19 from "../images/img-19.jpg"
import img21 from "../images/img-21.jpg"
import img22 from "../images/img-22.jpg"
import img23 from "../images/img-23.jpg"
import img42 from "../images/img42.jpg"
import img43 from "../images/img-43.jpg"
import img26 from "../images/img-26.jpg"
// import vid1 from "../images/vid-1.mp4"
// import vid2 from "../images/vid-2.mp4"
// import vid3 from "../images/vid-3.mp4"
// import vid4 from "../images/vid-4.mp4"
// import vid5 from "../images/vid-5.mp4"
// import vid6 from "../images/vid-6.mp4"

const Gallery = () => {
  return (
   <>
    <div className="containers">
<div
        className="text-center mx-auto mb-5 wow fadeInUp"
        data-wow-delay="0.1s"
        style={{ maxWidth: 600 }}
      >
        <h1 className="mb-3">School Gallery</h1>
        <p>
        A creative space showcasing students' artwork, projects, and achievements, celebrating their talent and fostering artistic expression.
        </p>
      </div>
  <div className="box">
    <div className="dream">
      <img src={img12} alt='img-1' />
      <img src={img14} alt='img-1' />
      <img src={img15} alt='img-1' />
      <img src={img19} alt='img-1' />
      {/* <img src={img5} alt='img-1' /> */}
    </div>
    <div className="dream">
    <img src={img21} alt='img-1' />
      <img src={img22} alt='img-1' />
      <img src={img23} alt='img-1' />
      <img src={img42} alt='img-1' />
      <img src={img43} alt='img-1' />
    </div>
    <div className="dream">
    <img src={img26} alt='img-1' />
      <img src={img34} alt='img-1' />
      <img src={img39} alt='img-1' />
      <img src={img40} alt='img-1' />
      <img src={img41} alt='img-1' />
    </div>
  </div>

  <div
        className="text-center mx-auto mb-5 wow fadeInUp"
        data-wow-delay="0.1s"
        style={{ maxWidth: 600 }}
      >
        <h1 className="mb-3 my-3">Videos Gallery</h1>
      </div>
  <div className="box">
    <div className="dream">
    {/* <video src={vid1} controls="0.1s" muted="" />
    <video src={vid2} controls="0.1s" muted="" /> */}
      {/* <img src={img15} alt='img-1' />
      <img src={img19} alt='img-1' /> */}
      {/* <img src={img5} alt='img-1' /> */}
    </div>
    <div className="dream">
    {/* <video src={vid3} controls="0.1s" muted="" />
    <video src={vid4} controls="0.1s" muted="" /> */}
      {/* <img src={img24} alt='img-1' />
      <img src={img25} alt='img-1' /> */}
    </div>
    <div className="dream">
    {/* <video src={vid5} controls="0.1s" muted="" />
    <video src={vid6} controls="0.1s" muted="" /> */}
    </div>
  </div>

</div>

{/* <VideosGal/> */}
   </>
  )
}

export default Gallery
