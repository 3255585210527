import React from "react";
import Footer from "../components/Footer";
import { Link } from "react-router-dom";
import logo2 from "../images/Final-Logo.png";
import WhatApp from "../components/WhatApp";

const ContactUsPage = () => {
  return (
    <>
      {/* Navbar Start */}
      <nav className="navbar navbar-expand-lg bg-white navbar-light sticky-top px-4 px-lg-5 py-lg-0">
        <Link to="/" className="navbar-brand">
          {/* <h1 className="m-0 text-primary">
            <i className="fa fa-book-reader me-3" />
            Kider
          </h1> */}
          <img
            src={logo2}
            style={{ width: "6rem", height: "6rem", marginLeft: "2.4rem" }}
            alt=""
          />
        </Link>
        <button
          type="button"
          className="navbar-toggler"
          data-bs-toggle="collapse"
          data-bs-target="#navbarCollapse"
        >
          <span className="navbar-toggler-icon" />
        </button>
        <div className="collapse navbar-collapse" id="navbarCollapse">
          <div className="navbar-nav mx-auto">
            <Link to="/" className="nav-item nav-link">
              Home
            </Link>
            <Link to="/about" className="nav-item nav-link">
              About Us
            </Link>
            <Link to="/class" className="nav-item nav-link">
              Features
            </Link>
            <Link to="/gallery" className="nav-item nav-link">
              Gallery
            </Link>
            <Link to="/contact" className="nav-item nav-link active">
              Contact Us
            </Link>
          </div>
          <a
            href="/"
            className="btn btn-primary rounded-pill px-3 d-none d-lg-block"
          >
            Join Us
            <i className="fa fa-arrow-right ms-3" />
          </a>
        </div>
      </nav>
      {/* Navbar End */}
      {/* Page Header End */}
      <div className="container-xxl py-5 page-header position-relative mb-5">
        <div className="container py-5">
          <h1 className="display-2 text-white animated slideInDown mb-4">
            Contact Us
          </h1>
          <nav aria-label="breadcrumb animated slideInDown">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="#">Home</a>
              </li>
              <li className="breadcrumb-item">
                <a href="#">Pages</a>
              </li>
              <li
                className="breadcrumb-item text-white active"
                aria-current="page"
              >
                Contact Us
              </li>
            </ol>
          </nav>
        </div>
      </div>
      {/* Page Header End */}
      {/* Contact Start */}
      <div className="container-xxl py-5">
        <div className="container">
          <div
            className="text-center mx-auto mb-5 wow fadeInUp"
            data-wow-delay="0.1s"
            style={{ maxWidth: 600 }}
          >
            <h1 className="mb-3">Get In Touch</h1>
            <p>
              Actively engage with teachers, staff, and peers to enhance
              learning, address concerns, and build a supportive community.
            </p>
          </div>
          <div className="row g-4 mb-5">
            <div
              className="col-md-6 col-lg-4 text-center wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div
                className="bg-light rounded-circle d-inline-flex align-items-center justify-content-center mb-4"
                style={{ width: 75, height: 75 }}
              >
                <i className="fa fa-map-marker fa-2x text-primary" />
              </div>
              <h6>S.23/42 A-7 Dhelwariya,Choukaghat,Varanasi</h6>
            </div>
            <div
              className="col-md-6 col-lg-4 text-center wow fadeInUp"
              data-wow-delay="0.3s"
            >
              <div
                className="bg-light rounded-circle d-inline-flex align-items-center justify-content-center mb-4"
                style={{ width: 75, height: 75 }}
              >
                <i className="fa fa-envelope fa-2x text-primary" />
              </div>
              <h6>scholarvallypublicschool@gmail.com</h6>
            </div>
            <div
              className="col-md-6 col-lg-4 text-center wow fadeInUp"
              data-wow-delay="0.5s"
            >
              <div
                className="bg-light rounded-circle d-inline-flex align-items-center justify-content-center mb-4"
                style={{ width: 75, height: 75 }}
              >
                <i className="fa fa-phone fa-2x text-primary" />
              </div>
              <h6>+91 7905308732</h6>
            </div>
          </div>
          <div className="bg-light rounded">
            <div className="row g-0">
              <div className="col-lg-6 wow fadeIn" data-wow-delay="0.1s">
                <div className="h-100 d-flex flex-column justify-content-center p-5">
                  <form action="https://formspree.io/f/xdovjpzy" method="POST">
                    <div className="row g-3">
                      <div className="col-sm-6">
                        <div className="form-floating">
                          <input
                            type="text"
                            className="form-control border-0"
                            id="name"
                            placeholder="Your Name"
                            name="name"
                          />
                          <label htmlFor="name">Your Name</label>
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-floating">
                          <input
                            type="email"
                            className="form-control border-0"
                            id="email"
                            placeholder="Your Email"
                            email="email"
                          />
                          <label htmlFor="email">Your Email</label>
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="form-floating">
                          <input
                            type="text"
                            className="form-control border-0"
                            id="subject"
                            placeholder="Subject"
                            name="subject"
                          />
                          <label htmlFor="subject">Subject</label>
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="form-floating">
                          <textarea
                            className="form-control border-0"
                            placeholder="Leave a message here"
                            id="message"
                            style={{ height: 100 }}
                            defaultValue={""}
                            name="message"
                          />
                          <label htmlFor="message">Message</label>
                        </div>
                      </div>
                      <div className="col-12">
                        <button
                          className="btn btn-primary w-100 py-3"
                          type="submit"
                        >
                          Send Message
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              <div
                className="col-lg-6 wow fadeIn"
                data-wow-delay="0.5s"
                style={{ minHeight: 400 }}
              >
                <div className="position-relative h-100">
                  <iframe
                    className="position-relative rounded w-100 h-100"
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d14423.90993298666!2d82.98492327610123!3d25.33853659433032!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x398e2e6e2649d99b%3A0x375d613c31fa063e!2sDhelwariya%2C%20Varanasi%2C%20Uttar%20Pradesh%20221001!5e0!3m2!1sen!2sin!4v1685076857729!5m2!1sen!2sin"
                    frameBorder={0}
                    style={{ minHeight: 400, border: 0 }}
                    allowFullScreen=""
                    aria-hidden="false"
                    tabIndex={0}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Contact End */}
      <Footer />
      <WhatApp/>
    </>
  );
};

export default ContactUsPage;
