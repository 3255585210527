import React from 'react'
import principal2 from "../images/principal-2.jpg";
import img35 from "../images/img-35.jpg"
import img36 from "../images/img-36.jpg"
import img37 from "../images/img-37.jpg"


const About = () => {
  return (
    <div>
       <div className="container-xxl py-5">
    <div className="container">
      <div className="row g-5 align-items-center">
        <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
          <h1 className="mb-4">
            Learn More About Our Work And Our Cultural Activities
          </h1>
          <p>
          An educational institution where students acquire knowledge, develop skills, and prepare for their future through structured learning, social interactions, and diverse experiences.
          </p>
          <p className="mb-4">
          A place where students of various ages gather to receive formal education, interact with peers and teachers, engage in academic and extracurricular activities, and acquire knowledge, skills, and values to prepare for their personal and professional lives.
          </p>
          <div className="row g-4 align-items-center">
            <div className="col-sm-6">
              <div className="d-flex align-items-center">
                <img
                  className="rounded-circle flex-shrink-0"
                  src={principal2}
                  alt=""
                  style={{ width: 45, height: 45 }}
                />
                <div className="ms-3">
                  <h6 className="text-primary mb-1">Mrs. Reeta Mishra</h6>
                  <small>Principal &amp; MA. Bed. N T T</small>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-6 about-img wow fadeInUp" data-wow-delay="0.5s">
          <div className="row">
            <div className="col-12 text-center">
              <img
                className="img-fluid w-75 rounded-circle bg-light p-3"
                src="/static/img/about-1.jpg"
                alt=""
              />
            </div>
            <div className="col-6 text-start" style={{ marginTop: "-150px" }}>
              <img
                className="img-fluid w-100 rounded-circle bg-light p-3"
                src="/static/img/about-2.jpg"
                alt=""
              />
            </div>
            <div className="col-6 text-end" style={{ marginTop: "-150px" }}>
              <img
                className="img-fluid w-100 rounded-circle bg-light p-3"
                src="/static/img/about-3.jpg"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
    </div>
  )
}

export default About
