import React from 'react'

const Facilities = () => {
  return (
    <div>
       <div className="container-xxl py-5">
    <div className="container">
      <div
        className="text-center mx-auto mb-5 wow fadeInUp"
        data-wow-delay="0.1s"
        style={{ maxWidth: 600 }}
      >
        <h1 className="mb-3">School Facilities</h1>
        <p>
        School facilities are essential for creating conducive learning environments. They encompass classrooms, libraries, laboratories, sports fields, and other spaces that support students' academic, social, and physical development.
        </p>
      </div>
      <div className="row g-4">
        <div className="col-lg-3 col-sm-6 wow fadeInUp" data-wow-delay="0.1s">
          <div className="facility-item">
            <div className="facility-icon bg-primary">
              <span className="bg-primary" />
              <i className="fa fa-bus fa-3x text-primary" />
              <span className="bg-primary" />
            </div>
            <div className="facility-text bg-primary">
              <h3 className="text-primary mb-3">School Bus</h3>
              <p className="mb-0">
               Safe, reliable transportation for students, ensuring they arrive at school and return home efficiently.
              </p>
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-sm-6 wow fadeInUp" data-wow-delay="0.3s">
          <div className="facility-item">
            <div className="facility-icon bg-success">
              <span className="bg-success" />
              <i className="fa fa-futbol fa-3x text-success" />
              <span className="bg-success" />
            </div>
            <div className="facility-text bg-success">
              <h3 className="text-success mb-3">Playground</h3>
              <p className="mb-0">
               Joyful area for children to play, learn, and unleash their creativity through active exploration.
              </p>
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-sm-6 wow fadeInUp" data-wow-delay="0.5s">
          <div className="facility-item">
            <div className="facility-icon bg-warning">
              <span className="bg-warning" />
              <i className="fa fa-home fa-3x text-warning" />
              <span className="bg-warning" />
            </div>
            <div className="facility-text bg-warning">
              <h3 className="text-warning mb-3">Healthy Canteen</h3>
              <p className="mb-0">
              Nourishing food choices for students, promoting wellness and positive eating habits.
              </p>
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-sm-6 wow fadeInUp" data-wow-delay="0.7s">
          <div className="facility-item">
            <div className="facility-icon bg-info">
              <span className="bg-info" />
              <i className="fa fa-laptop fa-3x text-info" />
              <span className="bg-info" />
            </div>
            <div className="facility-text bg-info">
              <h3 className="text-info mb-3">Positive Learning</h3>
              <p className="mb-0">
              Fostering an engaging, supportive environment that nurtures curiosity, growth, and a love for learning.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
    </div>
  )
}

export default Facilities
