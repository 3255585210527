import React from 'react'
import { Link } from "react-router-dom";
import logo2 from "../images/Final-Logo.png";
import Gallery from '../components/Gallery';
import Footer from '../components/Footer';
import WhatApp from '../components/WhatApp';


const GalleryPage = () => {
  return (
   <>
    <nav className="navbar navbar-expand-lg bg-white navbar-light sticky-top px-4 px-lg-5 py-lg-0">
    <Link to="/" className="navbar-brand">
          <img src={logo2} style={{width: "6rem", height: "6rem", marginLeft:"2.4rem"}} alt="" />
    </Link>
    <button
      type="button"
      className="navbar-toggler"
      data-bs-toggle="collapse"
      data-bs-target="#navbarCollapse"
    >
      <span className="navbar-toggler-icon" />
    </button>
    <div className="collapse navbar-collapse" id="navbarCollapse">
      <div className="navbar-nav mx-auto">
        <Link to="/" className="nav-item nav-link">
          Home
        </Link>
        <Link to="/about" className="nav-item nav-link ">
          About Us
        </Link>
        <Link to="/class" className="nav-item nav-link">
        Features
        </Link>
        <Link to="/gallery" className="nav-item nav-link active">
        Gallery
        </Link>
        <Link to="/contact" className="nav-item nav-link">
          Contact Us
        </Link>
      </div>
      <a
        href="/"
        className="btn btn-primary rounded-pill px-3 d-none d-lg-block"
      >
        Join Us
        <i className="fa fa-arrow-right ms-3" />
      </a>
    </div>
  </nav>

  <div className="container-xxl py-5 page-header position-relative mb-5">
    <div className="container py-5">
      <h1 className="display-2 text-white animated slideInDown mb-4">
        School Gallery
      </h1>
      <nav aria-label="breadcrumb animated slideInDown">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <a href="/">Home</a>
          </li>
          <li className="breadcrumb-item text-white active" aria-current="page">
          School Gallery
          </li>
        </ol>
      </nav>
    </div>
  </div>
  <Gallery/>
  <Footer/>
  <WhatApp/>
   </>
  )
}

export default GalleryPage
