import React from 'react';
import img35 from "../images/img-35.jpg"
import { Link } from 'react-router-dom';


const Action = () => {
  return (
    <div>
        <div className="container-xxl py-5">
    <div className="container">
      <div className="bg-light rounded">
        <div className="row g-0">
          <div
            className="col-lg-6 wow fadeIn"
            data-wow-delay="0.1s"
            style={{ minHeight: 400 }}
          >
            <div className="position-relative h-100">
              <img
                className="position-absolute w-100 h-100 rounded"
                src={img35}
                style={{ objectFit: "cover" }}
                alt='action'
              />
            </div>
          </div>
          <div className="col-lg-6 wow fadeIn" data-wow-delay="0.5s">
            <div className="h-100 d-flex flex-column justify-content-center p-5">
              <h1 className="mb-4">Become A Teacher</h1>
              <p className="mb-4">
              Inspire and empower future generations, fostering a love for learning, igniting curiosity, and nurturing the potential of each student.
              </p>
              <Link className="btn btn-primary py-3 px-5" to="/contact">
                Get Started Now
                <i className="fa fa-arrow-right ms-2" />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
    </div>
  )
}

export default Action
