import React from 'react'
import { Link } from 'react-router-dom'
import Footer from '../components/Footer'
import logo2 from "../images/Final-Logo.png";
import WhatApp from '../components/WhatApp';
import Team from '../components/Team';
import Action from '../components/Action';
import About from '../components/About';


const AboutPage = () => {
  return (
    <>
  <nav className="navbar navbar-expand-lg bg-white navbar-light sticky-top px-4 px-lg-5 py-lg-0">
    <Link to="/" className="navbar-brand">
          <img src={logo2} style={{ width: "6rem", height: "6rem", marginLeft:"2.4rem"}} alt="" />
    </Link>
    <button
      type="button"
      className="navbar-toggler"
      data-bs-toggle="collapse"
      data-bs-target="#navbarCollapse"
    >
      <span className="navbar-toggler-icon" />
    </button>
    <div className="collapse navbar-collapse" id="navbarCollapse">
      <div className="navbar-nav mx-auto">
        <Link to="/" className="nav-item nav-link">
          Home
        </Link>
        <Link to="/about" className="nav-item nav-link active">
          About Us
        </Link>
        <Link to="/class" className="nav-item nav-link">
        Features
        </Link>
        <Link to="/" className="nav-item nav-link">
        Gallery
        </Link>
        <Link to="/contact" className="nav-item nav-link">
          Contact Us
        </Link>
      </div>
      <a
        href="/"
        className="btn btn-primary rounded-pill px-3 d-none d-lg-block"
      >
        Join Us
        <i className="fa fa-arrow-right ms-3" />
      </a>
    </div>
  </nav>

  {/* Page Header End */}
  <div className="container-xxl py-5 page-header position-relative mb-5">
    <div className="container py-5">
      <h1 className="display-2 text-white animated slideInDown mb-4">
        About Us
      </h1>
      <nav aria-label="breadcrumb animated slideInDown">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <a href="/">Home</a>
          </li>
          <li className="breadcrumb-item text-white active" aria-current="page">
            About Us
          </li>
        </ol>
      </nav>
    </div>
  </div>
  {/* Page Header End */}
  {/* About Start */}
  <About/>
  {/* About End */}
  {/* Call To Action Start */}
  <Action/>
  {/* Call To Action End */}
  {/* Team Start */}
  <Team/>
  {/* Team End */}
  <Footer/>
  <WhatApp/>
</>

  )
}

export default AboutPage
